<template>
  <lobby-hall-page :main-hall-video-url='mainHallVideoUrl'>
    <lobby-hall-link
      position-style='top: 120px; left: calc(50% - 86px);'  
      routeName='ExhibitionHall'>
      온라인전시
    </lobby-hall-link>
    <lobby-hall-link
      position-style='top: 207px; left: 300px;'  
      routeName='Posters'>
      E-포스터
    </lobby-hall-link>
     <lobby-hall-link
      position-style='top: 346px; left: 100px;'  
      routeName='Notices'>
      공지사항
    </lobby-hall-link>
    <lobby-hall-link
      position-style='top: 200px; right: 400px;'  
      routeName='Programs'>
      프로그램
    </lobby-hall-link>
    <lobby-hall-link
      position-style='top: 472px; right: 280px;'  
      routeName='Welcome'>
      모시는 글
    </lobby-hall-link>
    <lobby-hall-link v-if='!hasValidToken'
      position-style='top: 510px; left: calc(50% - 150px);'  
      routeName='Login'>
      로그인
    </lobby-hall-link>
    <el-dialog
      :visible.sync='showLobbyModal'
      :fullscreen='dialogFullscreenStatus'
      :append-to-body='true'
      class='lobby-hall-page-modal'>
      <lobby-hall-modal-content style='height: calc(100% - 60px); overflow-y: scroll; overflow-x: hidden;' />
      <div>
        <!-- hide this control for now -->        
        <label class='flex flex-row justify-center items-center gap-x-1 p-2 hidden'>
          <input type='checkbox' v-model='saveHideModal' />
          <span>다시 보지않기</span>
        </label>
      </div>
    </el-dialog>
  </lobby-hall-page>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import LobbyHallModalContent from '@/components/ksso2022/LobbyHallModalContent.vue' // temporarily use this content for the modal
import LobbyHallPage         from '@/components/LobbyHallPage.vue'
import LobbyHallLink         from '@/components/LobbyHallLink.vue'

export default {
  name: 'LobbyHall',
  components: {
    LobbyHallLink,
    LobbyHallPage,
    LobbyHallModalContent,
  },
  data () {
    return {
      mainHallVideoUrl: 'https://player.vimeo.com/video/685840679?h=5458d4dc18&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1',
      showLobbyModal: false,
      saveHideModal: false,
    }
  },
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    dialogFullscreenStatus () {
      return this.isMobile
    },
    isMobile () {
      return window.navigator.userAgent.includes('Android') ||
             window.navigator.userAgent.includes('iPhone')
    },
  },
  watch: {
    showLobbyModal: {
      handler: function (newVal, oldVal) {
        if (!newVal && newVal !== oldVal && this.saveHideModal) {
          localStorage.setItem('dont_show_modal_again', 'saveHidden')
        }
      }, 
      immediate: true,
    }
  },
  methods: {
    ...mapActions('users', [
      'checkTokenStatus'
    ]),
  },
  created () {
    this.checkTokenStatus()
    if (localStorage.getItem('dont_show_modal_again') == 'saveHidden') {
      this.showLobbyModal = false
    }
  }
}
</script>

<style lang='scss'>
  .lobby-hall-page-modal .el-dialog.is-fullscreen {
    width: 100vw;
  }
</style>
