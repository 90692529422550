<template>
  <main-view />
</template>

<script>
import MainView from '@/views/ksso2022/Main.vue' // temporarily use this content for the modal

export default {
  name: 'LobbyHallModalContent',
  components: {
    MainView,
  },
}
</script>
